/* eslint-disable react/prop-types */
import React from "react";
import Tickers from "./tickers";
import Buttons from "./buttons";
const { Step } = Steps;
const { Panel } = Collapse;
import { Card, Collapse, Steps, Row, Col, Layout } from "antd";
import IsMobileView from "../../components/utils/mobileView";
import H2 from "../../components/typography/section-heading";

const { Content } = Layout;
const iconset = [
  "https://i.imgur.com/U8NINyM.png",
  "https://i.imgur.com/GW91z9V.png",
  "https://i.imgur.com/WgNjC36.png",
];
const Common = ({ checklist, auth_user }) => {
  const isMobileView = IsMobileView();
  return (
    <Content
      style={{
        padding: 24,
        marginTop: 0,
        width: "100%",
      }}
    >
      <Tickers checklist={checklist} />
      <Layout
        style={{
          /*background:
          "linear-gradient(-119.72deg,rgba(83,175,190,.15) 1.55%,rgba(231,237,254,0) 65.38%)",*/
          margin: "0px -48px",
          background: "black",
          paddingBottom: "48px",
        }}
      >
        {" "}
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          lg={{ span: 16, offset: 4 }}
          style={{
            display: "flex",
            marginTop: 16,
            flexDirection: "column",
            padding: "24px",
          }}
        >
          <H2 color="white">Generate Revenue from your Short Films!</H2>
          <br />
          <Row gutter={16}>
            {checklist.features &&
              checklist.features.map((item, index) => (
                <Col
                  xs={{ span: 22, push: 1 }}
                  lg={{ span: 8, push: 0 }}
                  key={index}
                  style={{ marginBottom: isMobileView ? "24px" : "0px" }}
                >
                  <Card
                    cover={<img alt={item.title} src={iconset[index]} />}
                    style={{
                      background: "transparent",
                      color: "white",
                      borderColor: "rgba(255, 255, 255, 0.2)",
                      borderRadius: "8px",
                      height: "100%",
                    }}
                  >
                    <h3
                      style={{
                        color: "white",
                        fontSize: "24px",
                        lineHeight: "1.2",
                        fontWeight: "bold",
                      }}
                    >
                      {item.title}
                    </h3>
                    {item.description}
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
      </Layout>
      <Layout
        style={{
          margin: "0px -48px",
          background: "black",
          paddingBottom: "48px",
        }}
        className="steps-section"
      >
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          lg={{ span: 16, offset: 4 }}
          style={{
            marginTop: 48,
            marginBottom: 48,
            padding: "24px",
          }}
        >
          <H2 color="white"> How it Works?</H2>
          {checklist.steps && (
            <Content
              style={{
                padding: 24,
              }}
            >
              <Steps
                current={-1}
                direction={window.innerWidth < 530 ? "vertical" : "horizontal"}
              >
                {checklist.steps.map((item, index) => (
                  <Step
                    key={index}
                    title={item.title}
                    description={item.description}
                  />
                ))}
              </Steps>
            </Content>
          )}
          <img
            src="/dashboard.png"
            alt="dashboard"
            width="100%"
            style={{ marginBottom: 64 }}
          />
          {auth_user && !auth_user.user && <Buttons />}
        </Col>
      </Layout>
      <Layout
        style={{
          background:
            "linear-gradient(-119.72deg,rgba(83,175,190,.15) 1.55%,rgba(231,237,254,0) 65.38%)",
          margin: "0px -48px",
          padding: "0px 0px 48px",
        }}
      >
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          lg={{ span: 16, offset: 4 }}
          style={{
            marginTop: 48,
          }}
        >
          <H2> FAQs</H2>
          {checklist.faq && (
            <Collapse
              defaultActiveKey={["0"]}
              style={{ textAlign: "left", margin: "0 48px" }}
            >
              {checklist.faq.map((item, index) => (
                <Panel header={item.title} key={index}>
                  <p>{item.description}</p>
                </Panel>
              ))}
            </Collapse>
          )}
        </Col>
      </Layout>
    </Content>
  );
};
export default Common;
