/* eslint-disable react/prop-types */
import React from "react";
import { Button, Space, Typography } from "antd";
const { Title } = Typography;
const isMobileView = window.innerWidth < 768;

const styles = {
  fontSize: isMobileView ? "16px" : "16px",
  height: "50px",
  fontFamily: "Roboto",
  textTransform: "uppercase",
  letterSpacing: isMobileView ? "1px" : "2px",
  fontWeight: "bold",
};
const Buttons = ({ single }) => (
  <>
    <Space
      direction="horizotal"
      style={{ justifyContent: "center", marginTop: "16px" }}
    >
      <Button
        type="primary"
        size={"large"}
        style={{
          background: "linear-gradient(270deg, #8DA0EC 0%, #4E63AC 133.33%)",
          borderRadius: single ? "50px" : "50px 0px 0px 50px",
          ...styles,
        }}
      >
        <a
          href={`https://shorted.in/submit/`}
          target="_blank"
          rel="noreferrer"
          title="Submit your Short Film"
        >
          Get Started
        </a>
      </Button>
      {!single && (
        <Button
          size={"large"}
          style={{
            borderRadius: "0px 50px 50px 0px",
            ...styles,
          }}
          onClick={() => {
            const element = document.getElementById("login");
            console.log(element);
            element.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Login
        </Button>
      )}
    </Space>
    <Title
      level={2}
      style={{
        marginTop: 16,
        fontSize: 13,
        fontWeight: 100,
        padding: "8px",
        color: "white",
      }}
    >
      If your short film is already featured on SHORTED, please login with the
      same account.
    </Title>
  </>
);
export default Buttons;
