/* eslint-disable react/prop-types */
import {
  Card,
  Col,
  Divider,
  Layout,
  Radio,
  Row,
  Statistic,
  Typography,
} from "antd";
import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

const { Title } = Typography;
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);
const get_date = (timestamp) => {
  var curdate = new Date(null);
  curdate.setTime(timestamp.seconds * 1000);
  return (
    curdate.getDate() +
    "/" +
    (curdate.getMonth() + 1) +
    "/" +
    curdate.getFullYear()
  );
};

const dates = (num, lastUpdated) =>
  [...Array(num)].map((_, i) => {
    if (lastUpdated) {
      const d = new Date(null);
      d.setTime(lastUpdated.seconds * 1000);
      d.setDate(d.getDate() - i);
      //console.log(d.getMonth() + "/" + d.getDate() + "/" + d.getFullYear());
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    }
  });

const accum = (arr, key) =>
  Object.values(arr).length
    ? Object.values(arr)
        .map((item) => item.reduce((n, data) => n + data[key], 0))
        .reduce((a, b) => a + b, 0)
    : 0;

const platforms = {
  uxrk08UWP9tKE0ehFf67: { title: "Disney+ Hotstar", color: "#12194A" },
  zEAGwI1OWReb9aPFQRsV: { title: "MX Player", color: "#4284F3" },
};
const dataArr = [
  {
    key: "sum__total_streams",
    title: "Total Streams",
  },
  {
    key: "sum__total_streaming_users",
    title: "Total Streaming Users",
  },
  {
    key: "sum__stream_duration",
    title: "Total Stream Duration (min)",
  },
];
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const Metric = ({ distribution, lastUpdated }) => {
  const validArr = distribution.platform.filter(
    (item) => item.metric && ["live", "offline"].includes(item.status)
  );
  const validRevArr = distribution.platform.filter(
    (item) => item.revenue && ["live", "offline"].includes(item.status)
  );
  const [numDays, updateNumDays] = useState(9);
  //const metric = validArr.length ? validArr[0].metric : [];
  //validArr.reduce((item, e) => item.metric.concat(e.metric))
  const metric =
    validArr.length && lastUpdated
      ? Object.assign(
          {},
          ...validArr.map((item) => ({ [item.id]: item.metric }))
        )
      : {};
  const revenue =
    validRevArr.length && lastUpdated
      ? Object.assign(
          {},
          ...validRevArr.map((item) => ({ [item.id]: item.revenue }))
        )
      : {};
  const labels = dates(numDays, lastUpdated).reverse();
  const newArr = {};
  const newRevArr = {};
  Object.keys(metric).map((x) => {
    newArr[x] = labels.map((item) =>
      metric[x].filter((data) => item === get_date(data.timestamp)).length
        ? metric[x].filter((data) => item === get_date(data.timestamp))[0]
        : {
            sum__total_streams: 0,
            sum__stream_duration: 0,
            sum__total_streaming_users: 0,
          }
    );
  });
  Object.keys(revenue).map((x) => {
    newRevArr[x] = labels.map((item) =>
      revenue[x].filter((data) => item === get_date(data.timestamp)).length
        ? revenue[x].filter((data) => item === get_date(data.timestamp))[0]
        : {
            sum__share_revenue: 0,
          }
    );
  });
  const data = {
    labels,
    datasets: dataArr.map((item) =>
      Object.keys(newArr).map((x) => ({
        label: platforms[x].title,
        data: newArr[x].map((data) => data[item.key] || 0),
        borderColor: platforms[x].color,
        backgroundColor: platforms[x].color,
        fill: false,
      }))
    ),
  };
  const revData = {
    labels,
    datasets: Object.keys(newRevArr).map((x) => ({
      label: platforms[x].title,
      data: newRevArr[x].map((data) => data["sum__share_revenue"] || 0),
      borderColor: platforms[x].color,
      backgroundColor: platforms[x].color,
      fill: false,
    })),
  };
  const sum__total_streams = accum(newArr, "sum__total_streams");
  const sum__total_streaming_users = accum(
    newArr,
    "sum__total_streaming_users"
  );
  const sum__stream_duration = accum(newArr, "sum__stream_duration");
  const sum__share_revenue = accum(newRevArr, "sum__share_revenue");
  const filterChange = (e) => {
    console.log(e.target.value);
    updateNumDays(parseInt(e.target.value));
  };
  const screenWidth = window ? window.innerWidth : 0;

  const StatisticsCard = ({ screenWidth, title, slug }) => (
    <Col
      xs={{ span: validRevArr.length > 0 ? 12 : 8 }}
      lg={{ span: validRevArr.length > 0 ? 6 : 8 }}
      style={{
        display: screenWidth > 768 ? "block" : "flex",
        marginBottom: "16px",
      }}
    >
      <Card style={{ width: "100%", height: "100%" }}>
        <Statistic title={title} value={slug} />{" "}
      </Card>
    </Col>
  );
  return (
    <>
      <Divider />
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Title level={4}>Streaming Analytics</Title>
          Last Updated : {lastUpdated && get_date(lastUpdated)}
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 12 }}
          style={{
            alignItems: screenWidth > 768 ? "flex-end" : "flex-start",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
          }}
        >
          <Row>
            <Radio.Group
              defaultValue="7"
              buttonStyle="solid"
              onChange={filterChange}
            >
              <Radio.Button value="7">7 days</Radio.Button>
              <Radio.Button value="30">30 days</Radio.Button>
              <Radio.Button value="90">90 days</Radio.Button>
            </Radio.Group>
          </Row>
          <Row style={{ paddingTop: "8px" }}>
            {labels[0]} - {labels[labels.length - 1]}
          </Row>
        </Col>
      </Row>
      <Layout style={{ background: "white" }}>
        <Row gutter={16} style={{ padding: "8px" }}>
          {validRevArr.length > 0 && (
            <StatisticsCard
              title="Total Revenue"
              slug={`₹ ${parseFloat(sum__share_revenue).toFixed(2)}`}
            />
          )}
          <StatisticsCard title="Total Streams" slug={sum__total_streams} />
          <StatisticsCard
            title="Total Streaming Users"
            slug={sum__total_streaming_users}
          />
          <StatisticsCard
            title="Total Stream Duration (min)"
            slug={sum__stream_duration}
          />
        </Row>
        <br />
        <br />
        {distribution && distribution.platform && (
          <>
            {validRevArr.length > 0 && (
              <>
                <Title level={5}>Revenue (INR)</Title>
                <Line
                  options={options}
                  data={{
                    ...revData,
                    datasets: revData.datasets,
                  }}
                  height={screenWidth > 768 ? "30%" : "50%"}
                  width="100%"
                />
              </>
            )}
            {data.datasets.map((item, index) => (
              <>
                <Title level={5} style={{ marginTop: 64 }}>
                  {dataArr[index].title}
                </Title>
                <Line
                  options={options}
                  data={{ ...data, datasets: item }}
                  height={screenWidth > 768 ? "30%" : "50%"}
                  width="100%"
                  key={item.label}
                />
              </>
            ))}
          </>
        )}
      </Layout>
    </>
  );
};
export default Metric;
