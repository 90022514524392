/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { PDFDocument } from "pdf-lib";
import {
  Input,
  Button,
  Row,
  Col,
  Pagination,
  Divider,
  Form,
  Checkbox,
  message,
} from "antd";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import SignatureCanvas from "react-signature-canvas";
import { storage, db_user } from "../../../components/firestore_user";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    lg: {
      span: 16,
      offset: 8,
    },
  },
};

const formUrl = "/form.pdf";

// Create a new PDFDocument
const Contract = ({ project }) => {
  const [steps, toggleSteps] = useState(0);
  const [loading, toggleLoading] = useState(false);
  const [pdfInfo, setPdfInfo] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [formValues, updateFormValues] = useState({});
  //const [license, updateLicense] = useState({});
  const inputEl = useRef(null);
  const pdfRef = useRef(null);
  const buttonRef = useRef(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const loadPdf = async () => {
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Flatten the form's fields
    form.flatten();

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    setPdfInfo(blob);
  };

  const onFinish = async (values) => {
    setPageNumber(1);
    if (inputEl.current.isEmpty()) {
      message.error("Please add your signatures");
      return 0;
    }
    updateFormValues(JSON.parse(JSON.stringify(values)));
    const hide = message.loading("Generating Contract", 0);
    try {
      const formPdfBytes = await fetch(formUrl).then((res) =>
        res.arrayBuffer()
      );

      // Load a PDF with form fields
      const pdfDoc = await PDFDocument.load(formPdfBytes);

      // add image
      const signImage = inputEl.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      const signImageBytes = await fetch(signImage).then((res) =>
        res.arrayBuffer()
      );
      const pages = pdfDoc.getPages();
      const page = pages[2];

      page.drawImage(await pdfDoc.embedPng(signImageBytes), {
        x: 80,
        y: page.getHeight() - 220,
        width: 30,
        height: 30,
      });

      // Get the form containing all the fields
      const form = pdfDoc.getForm();

      // Fill the form's fields
      Object.keys(values).map(
        (key) =>
          key !== "specifications" &&
          form.getTextField(key).setText(values[key])
      );
      form.getTextField("sign_name").setText(values.name);
      form.getTextField("title").setText(project.title);
      const current_date = new Date();
      form
        .getTextField("start_date")
        .setText(
          current_date.getDate() +
            "/" +
            (current_date.getMonth() + 1) +
            "/" +
            current_date.getFullYear()
        );
      form
        .getTextField("start_end_date")
        .setText(
          current_date.getDate() +
            "/" +
            (current_date.getMonth() + 1) +
            "/" +
            current_date.getFullYear() +
            "-" +
            current_date.getDate() +
            "/" +
            (current_date.getMonth() + 1) +
            "/" +
            (current_date.getFullYear() + 3)
        );
      // Flatten the form's fields
      form.flatten();

      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save();

      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      hide();
      //const docUrl = URL.createObjectURL(blob);
      setPdfInfo(blob);
      toggleSteps(2);
      toggleLoading(false);
      buttonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } catch (error) {
      console.log(error);
      message.error("Something went wrong. Please contact support");
    }
  };
  const submit = () => {
    const hide = message.loading("Submitting Contract", 0);
    // store pdf in storage and update database
    const uploadTask = storage.ref(`/d-contracts/${project.uid}`).put(pdfInfo);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      () => {
        //takes a snap shot of the process as it is happening
        //console.log(snapShot);
      },
      (err) => {
        //catches the errors
        console.log(err);
        message.error("Something went wrong. Please contact support");
      },
      async () => {
        const fireBaseUrl = await storage
          .ref("d-contracts")
          .child(project.uid)
          .getDownloadURL();
        await db_user
          .collection("projects")
          .doc(project.uid)
          .update({
            distributionStatus: "contract-filled",
            distributionDate: new Date(),
            distributionLicense: {
              ...formValues,
              fireBaseUrl: fireBaseUrl,
              date: new Date(),
            },
          });
        message.success("Contract Sent");
        setTimeout(() => {
          location.reload();
        }, 1000);
        hide();
      }
    );
  };
  const sign = () => {
    toggleSteps(1);
    buttonRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const download = () => {
    window.open(URL.createObjectURL(pdfInfo));
  };
  const onPaginationChange = (page) => {
    setPageNumber(page);
  };
  useEffect(() => {
    loadPdf();
  }, []);

  const Buttons = () => (
    <Col span={24}>
      {steps === 0 && (
        <Button onClick={() => sign()} type="primary" size="large" block>
          Click to Fill
        </Button>
      )}
      {steps == 2 && (
        <Row>
          <Col
            xs={{
              span: 24,
            }}
            lg={{
              span: 12,
            }}
            style={{ marginBottom: "12px" }}
          >
            <Button type="primary" onClick={() => submit()} size="large" block>
              Submit Contract
            </Button>
          </Col>
          <Col
            xs={{
              span: 24,
            }}
            lg={{
              span: 12,
            }}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button onClick={() => download()} block>
              Download
            </Button>
            <Button onClick={() => sign()} block>
              Edit
            </Button>
          </Col>
        </Row>
      )}
    </Col>
  );
  return (
    <div ref={buttonRef}>
      {steps === 0 || steps === 2 ? (
        <Row>
          <Buttons />
          <Col span={24} style={{ paddingTop: 24 }} ref={pdfRef}>
            {pdfInfo && URL.createObjectURL(pdfInfo).length > 0 && (
              <div
                style={{
                  width: "100%",
                  overflowX: "auto",
                  border: "1px solid black",
                  marginBottom: "10px",
                  minHeight: `${
                    pdfRef.current &&
                    pdfRef.current.offsetWidth *
                      (window.innerWidth < 720 ? 2.75 : 1.35)
                  }px`,
                }}
              >
                <Document
                  file={URL.createObjectURL(pdfInfo)}
                  onLoadSuccess={onDocumentLoadSuccess}
                  width={"200px"}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>
            )}
          </Col>
          {pdfInfo && URL.createObjectURL(pdfInfo).length > 0 && numPages && (
            <Col span={24}>
              <Pagination
                defaultCurrent={1}
                total={numPages * 10}
                simple
                onChange={onPaginationChange}
              />
            </Col>
          )}
          <Divider />
          <Buttons />
        </Row>
      ) : (
        <>
          <>Please fill the license details</>
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => onFinish(values, "review")}
            validateMessages={validateMessages}
            style={{ marginTop: 48, marginBottom: 48 }}
          >
            <Form.Item
              name="name"
              label="Licensor Name"
              initialValue={formValues && formValues.name}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="address"
              label="Licensor Address"
              initialValue={formValues && formValues.address}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              initialValue={formValues && formValues.email}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone"
              initialValue={formValues && formValues.phone}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="ac_name"
              label="A/C Name"
              initialValue={formValues && formValues.ac_name}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="ac_number"
              label="A/C Number"
              initialValue={formValues && formValues.ac_number}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="ac_bank"
              label="Bank"
              initialValue={formValues && formValues.ac_bank}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="ifsc"
              label="IFSC Code"
              initialValue={formValues && formValues.ifsc}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="pan"
              label="PAN"
              initialValue={formValues && formValues.pan}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Col {...tailFormItemLayout.wrapperCol}>
              {`Please draw your signatures below:`}
            </Col>
            <Col
              style={{
                border: "1px solid black",
                marginTop: "20px",
                overflow: "hidden",
              }}
              {...tailFormItemLayout.wrapperCol}
            >
              <SignatureCanvas
                canvasProps={{
                  dotSize: 20,
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
                ref={inputEl}
              />
            </Col>
            <br />
            <Form.Item
              name="specifications"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Please accept the terms and conditions")
                        ),
                },
              ]}
              {...tailFormItemLayout}
            >
              <Checkbox>
                I have read the contract and I accept the terms and conditions.
              </Checkbox>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                size="large"
                block
              >
                Generate Contract
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
export default Contract;
