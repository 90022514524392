/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

const placeholders = [
  "https://img.airtel.tv/unsafe/fit-in/326x0/filters:format(webp)/https://image.airtel.tv/HUNGAMA/HUNGAMA_VIDEO_93506215/LANDSCAPE_169/93506215_1280x720.jpg?o=production",
  "https://qqcdnpictest.mxplay.com/pic/40a370f38812266e96143c236911864e/en/16x9/480x270/test_pic1657784800731.webp",
  "https://qqcdnpictest.mxplay.com/pic/61dfe8b98b74395355dae34d23e2f766/en/16x9/480x270/test_pic1659002935358.webp",
  "https://qqcdnpictest.mxplay.com/pic/5747bca039228af609b5f2c7b6fd0d15/en/16x9/480x270/test_pic1665386950064.webp",
  "https://qqcdnpictest.mxplay.com/pic/9c21142841d7c50c2d8e26cd33e8ba4d/en/16x9/480x270/test_pic1662101602279.webp",
  "https://qqcdnpictest.mxplay.com/pic/0a866bf437adc7da24f8cf2a29cccd79/en/16x9/480x270/test_pic1658823384104.webp",
  "https://qqcdnpictest.mxplay.com/pic/401c3f16983232f00527d323dc3554de/en/16x9/480x270/test_pic1658910818015.webp",
];

const ImageFromApi = ({
  images,
  width,
  background,
  border,
  placeholder = false,
  height,
  borderRadius,
}) => {
  const [image, setImage] = useState("");
  //const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (images) {
      setImage(images);
    }
  }, [images]);

  return (
    <div
      style={{
        width: width || "150px",
        height: height || "100px",
        background: background || "#FFF",
      }}
    >
      <img
        src={
          image
            ? image.image
            : placeholder
            ? placeholders[Math.floor(Math.random() * placeholders.length)]
            : ""
        }
        style={{
          height: height || "100px",
          border: border || "none",
          borderRadius: borderRadius || "10px",
          width: "auto",
        }}
        alt={image ? image.title : ""}
      />
    </div>
  );
};
export default ImageFromApi;
