/* eslint-disable react/prop-types */
import React from "react";
import Marquee from "../../components/marquee";
import H2 from "../../components/typography/section-heading";

const arr = (checklist, i) => {
  const len = JSON.parse(checklist.films).length;
  const a = JSON.parse(checklist.films).slice(
    (i * len) / 2,
    (i * len) / 2 + len / 2
  );
  const titles = ["Umeed", "Faasle"];
  return a
    .filter((item) => !titles.includes(item.title))
    .map((item) => ({
      title: item.title,
      image: `https://img.airtel.tv/unsafe/fit-in/326x0/filters:format(webp)/https://image.airtel.tv/HUNGAMA/HUNGAMA_VIDEO_${item.link}/LANDSCAPE_169/${item.link}_1280x720.jpg?o=production${item.link}`,
    }));
};
const isMobileView = window.innerWidth < 768;
const Tickers = ({ checklist }) => (
  <>
    <H2> Our Distribution Partners (OTT/Broadcast)</H2>
    {checklist.platforms && (
      <div
        style={{
          padding: "20px 0px 20px 0px",
          margin: "0px -48px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage:
              "linear-gradient(to right, white, white 15%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), white 85%, white)",
            zIndex: 1,
          }}
        ></div>
        {checklist.platforms && (
          <Marquee
            images={checklist.platforms}
            width={150}
            margin={25}
          ></Marquee>
        )}
      </div>
    )}
    {checklist.films && (
      <>
        <div
          style={{
            padding: "24px 0px",
            background: "black",
            margin: "0px -48px",
          }}
        >
          <H2 color="white">500+ Short Films Released on Various Platforms</H2>
          <br />
          <Marquee
            images={checklist.featured}
            width={isMobileView ? 194 : 254}
            margin={0}
            height={isMobileView ? 291 : 381}
            borderRadius="0px"
            reverse
          ></Marquee>
          <br />
          {Array.from({ length: 2 }).map((x, i) => (
            <>
              <Marquee
                images={arr(checklist, i)}
                width={200}
                margin={0}
                height={100}
                border="8px solid #383343"
                borderRadius="8px"
                reverse={i === 1}
                background="transparent"
                animationSpeed={80}
              ></Marquee>
              {/*<Ticker
                speed={10}
                move={true}
                height={100}
                direction={i === 1 ? "toRight" : "toLeft"}
                offset={i === 1 ? "100%" : 0}
              >
                {({ index }) => {
                  return (
                    <ImageFromApi
                      images={arr(checklist, index, i)}
                      width={200}
                      background="transparent"
                      border="8px solid #383343"
                      placeholder={true}
                    />
                  );
                }}
              </Ticker>*/}
              {i !== 2 && <br />}
            </>
          ))}
        </div>
      </>
    )}
  </>
);
export default Tickers;
