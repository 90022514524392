/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import statusColor from "../utils/satus";
import { db_user } from "../firestore_user";
import { Collapse, Tag, Table, Typography } from "antd";

const { Title } = Typography;
const { Panel } = Collapse;

const tag = (item) => (
  <Tag
    color={
      (statusColor[item.status] && statusColor[item.status].color) || "warning"
    }
    icon={statusColor[item.status] && statusColor[item.status].icon}
  >
    {item.status}
  </Tag>
);

const columns = [
  {
    title: "Terms",
    dataIndex: "description",
    key: "description",
    width: "40%",
  },
  {
    title: "Status",
    dataIndex: "comments",
    key: "comments",
    // eslint-disable-next-line no-unused-vars
    render: (text, record) => (
      <>
        {tag(record)}
        <br /> <br />
        {["blocked", "rejected", "offline"].includes(record.status) ? (
          `Message from Platform - "${text}"`
        ) : record.status == "live" ? (
          <>
            Your film is live. Watch it{" "}
            <u>
              <a href={text} target="_blank" rel="noreferrer">
                here
              </a>
            </u>
            .<br />
          </>
        ) : (
          "The film is under QC process. You will be notified once it starts streaming."
        )}
      </>
    ),
  },
];
function convertHungamaURL(originalURL) {
  // Check if the URL matches the Hungama pattern
  const hungamaRegex = /https:\/\/www\.hungama\.com\/movie\/(.*)\/(\d+)\//;
  const match = originalURL.match(hungamaRegex);

  if (match) {
    // Extract the movie name and video ID
    const movieName = match[1];
    const videoID = match[2];

    // Construct the dynamic segment using the movie name
    const dynamicSegment = movieName.toLowerCase().replace(/\s+/g, "-");

    // Construct the Airtel Xstream URL with the dynamic segment
    const airtelXstreamURL = `https://www.airtelxstream.in/videos/${dynamicSegment}/HUNGAMA_VIDEO_${videoID}`;

    return airtelXstreamURL;
  } else {
    return "https://www.airtelxstream.in/";
  }
}

const airtelObj = (link) => ({
  title: (
    <div style={{ display: "inline-flex", alignItems: "baseline" }}>
      <Title level={5}>Airtel Xstream</Title>
      <div style={{ width: "8px" }}></div>
      <Tag
        color={(statusColor["live"] && statusColor["live"].color) || "warning"}
        icon={statusColor["live"] && statusColor["live"].icon}
      >
        {"live"}
      </Tag>
    </div>
  ),
  description: (
    <>
      <ul style={{ paddingLeft: 5 }}>
        <li>
          This platform is linked to Hungama Play and revenue reports will be
          integrated with Hungama Play.
        </li>
      </ul>
    </>
  ),
  status: "live",
  comments: convertHungamaURL(link),
});
const Platform = ({ distribution, handleLastUpdated }) => {
  const [platform, updatePlatform] = useState([]);
  const load = async () => {
    if (distribution.platform) {
      const platformArr = [];
      for (var i = 0; i < distribution.platform.length; i++) {
        //console.log(distribution.platform[i].id);
        const docPlatform = await db_user
          .collection("distribution-platforms")
          .doc(distribution.platform[i].id)
          .get();
        const platformData = docPlatform.data();
        platformArr.push({
          ...distribution.platform[i],
          title: (
            <div style={{ display: "inline-flex", alignItems: "baseline" }}>
              <Title level={5}> {platformData.title}</Title>
              <div style={{ width: "8px" }}></div>
              {tag(distribution.platform[i])}
            </div>
          ),
          description: (
            <>
              {distribution.platform[i].status === "live" && (
                <>
                  {distribution.platform[i].linked ===
                  "WdkSRjlvGdM24eJpym8D" ? (
                    <ul style={{ paddingLeft: 5 }}>
                      <li>
                        This platform is linked to Hungama Play and revenue
                        reports will be integrated with Hungama Play.
                      </li>
                    </ul>
                  ) : (
                    <ul style={{ paddingLeft: 5 }}>
                      <li>
                        {platformData.exclsuive ? "Exclusive" : "Non-Exclusive"}
                      </li>
                      <li>
                        {platformData.revShare ? "Revenue Share" : "One-Time"} |{" "}
                        {platformData.value}
                        {platformData.revShare && "%"}
                      </li>
                      {platformData.mode && <li>{platformData.mode}</li>}
                      <li>{platformData.period} years</li>
                      {platformData.payout && (
                        <li>
                          {platformData.payout.charAt(0).toUpperCase() +
                            platformData.payout.slice(1)}{" "}
                          payouts
                        </li>
                      )}
                      {platformData.metric && <li>{platformData.metric}</li>}
                    </ul>
                  )}
                </>
              )}
            </>
          ),
        });
        if (platformData.lastUpdated) {
          handleLastUpdated(platformData.lastUpdated);
        }
        //console.log(docPlatform.data());
      }
      // add airtel if live on Hungama
      if (
        platformArr.filter(
          (item) => item.id === "WdkSRjlvGdM24eJpym8D" && item.status === "live"
        ).length > 0
      ) {
        platformArr.push(
          airtelObj(
            platformArr.filter((item) => item.id === "WdkSRjlvGdM24eJpym8D")[0]
              .comments
          )
        );
      }
      updatePlatform(platformArr);
    }
  };
  useEffect(() => {
    load();
  }, [distribution]);
  return (
    <>
      {platform.length > 0 && (
        <>
          <Title level={4}>Platforms</Title>
          <Collapse>
            {platform.map((item, index) => (
              <Panel header={item.title} key={index}>
                <Table
                  dataSource={[item]}
                  columns={columns}
                  pagination={false}
                />
              </Panel>
            ))}
          </Collapse>
        </>
      )}
    </>
  );
};

export default Platform;
