/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Checkbox,
  Typography,
} from "antd";
import moment from "moment";
import { db_user } from "../../../components/firestore_user";
import List from "antd/lib/list";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import message from "antd/lib/message";
const { Title } = Typography;

const bordered = false;

const features = {
  pro: [
    {
      feature: "Hosting & streaming on SHORTED for one year (optional).",
      available: true,
    },
    {
      feature: "Distribution on OTT partners' network.",
      available: true,
    },
    {
      feature: "Secure storage for your short film.",
      available: true,
    },
    {
      feature: "Monetization options available on SHORTED.",
      available: true,
    },
    {
      feature: "Access to dashboard for revenue tracking.",
      available: true,
    },
    {
      feature: "Dedicated customer support.",
      available: true,
    },
  ],
};

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const renderFeature = (feature, available) => {
  const icon = available ? (
    <CheckOutlined style={{ color: "green" }} />
  ) : (
    <CloseOutlined style={{ color: "red" }} />
  );

  return (
    <List.Item
      style={{
        border: bordered ? "1px solid rgba(255,255,255,0.2)" : "none",
        fontFamily: "Roboto Condensed",
        fontWeight: 100,
      }}
    >
      <List.Item.Meta
        title={
          <span style={{ color: "black", fontSize: 15, fontWeight: 100 }}>
            {feature}
          </span>
        }
        avatar={icon}
      />
    </List.Item>
  );
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    lg: {
      span: 16,
      offset: 8,
    },
  },
};

function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf("day");
}

const RegisterForm = ({ auth_user, status, uid, updateStatus, project }) => {
  const [loading, toggleLoading] = useState(false);

  useEffect(() => {
    var aScript = document.createElement("script");
    aScript.type = "text/javascript";
    aScript.src = "https://checkout.razorpay.com/v1/checkout.js";

    document.head.appendChild(aScript);
    aScript.onload = function () {
      //console.log("loaded");
      //razorpay();
    };
  }, []);

  const update_db = async (payment_id, host, subscription_id, signature) => {
    toggleLoading(true);
    const obj = {
      project: project.uid,
      title: project.title,
      date: new Date(),
      payment_id: payment_id || "",
      subscription_id: subscription_id || "",
      signature: signature || "",
      host: host || "shorted.in",
      user: auth_user.user.uid,
      status: "success",
      subscription: "business",
      id: "business",
    };
    console.log(obj);
    try {
      await db_user.collection("pro").add(obj);
      await db_user.collection("projects").doc(project.uid).update({
        subscription: "business",
      });
      toggleLoading(false);
      location.reload();
    } catch (error) {
      toggleLoading(false);
      console.log(error);
      message.error("Something went wrong. Please contact support");
    }
  };
  // eslint-disable-next-line no-unused-vars
  async function razorpay(uid) {
    event.preventDefault();
    toggleLoading(true);
    var options = {
      name: "SHORTED",
      key: "rzp_live_sTBykzNhjSR764",
      image: "https://shorted.in/images/icons-512.png",
      notes: {},
      theme: {
        color: "#171C28",
      },
      description: "*This includes GST",
      modal: {
        ondismiss: function () {
          toggleLoading(false);
        },
      },
    };
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      plan_id: "plan_MvEo8hwjYwYY9a",
      project: uid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const resp = await fetch(
      "https://shorted.in/payments/razorpay-sub/",
      requestOptions
    );
    const result = await resp.text();
    options = {
      ...options,
      subscription_id: result,
      description: "Annual subscription includes GST",
      handler: function (response) {
        update_db(
          response.razorpay_payment_id,
          "shorted.in",
          response.razorpay_subscription_id,
          response.razorpay_signature
        );
      },
    };
    // eslint-disable-next-line no-undef
    var rzp1 = new Razorpay(options);
    // End Payment
    rzp1.open();
    // email
  }
  const onFinish = async (values, status) => {
    toggleLoading(true);
    console.log(values);
    if (status == "incomplete") {
      values.releaseDate = moment(values.releaseDate).toDate();
    } else {
      delete values.checklist;
      delete values.folderStructure;
      delete values.specifications;
      delete values.subtitles;
    }
    values.distributionStatus = status;
    values.distributionDate = new Date();
    await db_user.collection("projects").doc(uid).update(values);
    updateStatus();
    toggleLoading(false);
  };
  return (
    <>
      {["business", "black"].includes(project.subscription) ? (
        <>
          {status == "incomplete" ? (
            <Form
              {...layout}
              name="nest-messages"
              onFinish={(values) => onFinish(values, "review")}
              validateMessages={validateMessages}
              style={{ marginTop: 48, marginBottom: 48 }}
            >
              <Form.Item
                name="distributionDrive"
                label="Google Drive Link"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="A single Google Drive URL" />
              </Form.Item>
              <Col {...tailFormItemLayout.wrapperCol}>
                {`Please follow the template folder structure and grant 'Editor' access to `}
                <b>support@shortedfilms.com</b>
              </Col>
              <br />
              <Form.Item
                name="checklist"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Please read the checklist")
                          ),
                  },
                ]}
                {...tailFormItemLayout}
              >
                <Checkbox>I have read the checklist below.</Checkbox>
              </Form.Item>
              <Form.Item
                name="folderStructure"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Please follow the folder structure")
                          ),
                  },
                ]}
                {...tailFormItemLayout}
              >
                <Checkbox>
                  I have downloaded the template and added files in the correct
                  folders.
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="specifications"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Should follow the technical specifications"
                            )
                          ),
                  },
                ]}
                {...tailFormItemLayout}
              >
                <Checkbox>
                  I have followed the technical specifications given for the
                  video file and posters.
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="subtitles"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Should follow the subtitle specifications"
                            )
                          ),
                  },
                ]}
                {...tailFormItemLayout}
              >
                <Checkbox>
                  I have added subtitles as a separate file (.SRT) and the
                  subtitles are NOT burnt on the video. ( tick for a silent film
                  without subtitles)
                </Checkbox>
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  size="large"
                >
                  Complete Submission
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Form
              {...layout}
              name="nest-messages"
              onFinish={(values) => onFinish(values, "incomplete")}
              validateMessages={validateMessages}
            >
              <Form.Item
                name="synopsis"
                label="Synopsis"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Enter a brief synopis of the film"
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item
                name="releaseDate"
                label="Release Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item
                name="publisherDescription"
                label="Publisher Description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Enter a brief introduction about the creator/team."
                  maxLength={200}
                />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                >
                  Next
                </Button>
              </Form.Item>
            </Form>
          )}
        </>
      ) : (
        <>
          <p>Upgrade to Business Plan to start monetising your short film.</p>
          <List
            dataSource={features["pro"]}
            renderItem={(item) => renderFeature(item.feature, item.available)}
            bordered={bordered}
            style={{
              margin: "0 auto",
              maxWidth: "600px",
              border: false && "1px solid rgba(255,255,255,0.2)",
              width: "100%",
            }}
          />
          <div
            style={{
              textAlign: "center",
              fontFamily: "Roboto Condensed",
              backgroundColor: "#EFF2F5",
              padding: "24px",
              margin: "24px auto",
            }}
          >
            <p
              style={{
                letterSpacing: 1,
                marginBottom: 16,
                fontWeight: 600,
              }}
            >
              SUBSCRIPTION (1 YEAR )
            </p>
            <Title level={3} style={{ color: "#4E63AC" }}>
              ₹1999
            </Title>
            This covers the costs for ingestion, hosting, and streaming for a
            single project over one year. Additional GST charges apply.
            <br />
            <br />
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              onClick={() => razorpay()}
              style={{
                background:
                  "linear-gradient(270deg, #8DA0EC 0%, #4E63AC 133.33%)",
                border: 0,
              }}
            >
              UPGRADE
            </Button>
          </div>
        </>
      )}
    </>
  );
};
export default RegisterForm;
