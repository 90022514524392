import React from "react";
import { array, string, bool } from "prop-types";
import "./style.css";
import ImageFromApi from "./imageFromApi";
const propTypes = {
  images: array.isRequired,
  width: string,
  margin: string,
  height: string,
  reverse: bool,
  borderRadius: string,
  background: string,
  border: string,
  animationSpeed: string,
};
const Marquee = ({
  borderRadius,
  images,
  width,
  margin,
  height,
  reverse,
  background,
  border,
  animationSpeed = 20,
}) => {
  const marqueeInnerClass = reverse
    ? "marquee--inner reverse"
    : "marquee--inner";

  const orbs = images.map((item, index) => (
    <div
      className="orb"
      key={index}
      style={{
        width: `${width}px`,
        marginLeft: `${margin}px`,
        marginRight: `${margin}px`,
        height: `${height}px`,
      }}
    >
      <ImageFromApi
        images={item}
        width={width}
        height={height}
        borderRadius={borderRadius}
        background={background}
        border={border}
      />
    </div>
  ));
  return (
    <div
      className="marquee"
      style={{
        width: `${images.length * (Number(width) + 2 * Number(margin))}px`,
        height: `${height}px`,
      }}
    >
      <div
        className={marqueeInnerClass}
        style={{ animationDuration: `${animationSpeed}s` }}
      >
        <span>{orbs}</span>
        <span>{orbs}</span>
      </div>
    </div>
  );
};
Marquee.propTypes = propTypes;
export default Marquee;
