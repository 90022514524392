import React, { useState, useEffect } from "react";
import "./App.css";
import "antd/dist/antd.css";
import UserContext from "./components/utils/UserContext";
import AuthUser from "./components/utils/auth_user";
import { BrowserRouter, Route } from "react-router-dom";
import Wrapper from "./pages/wrapper";
import Projects from "./pages/dashboard/index";
import Project from "./pages/project/index";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";

const App = () => {
  const [auth_user, updateAuthUser] = useState({});
  const updateUser2 = (auth) => {
    updateAuthUser(auth);
  };

  ReactGA.initialize("G-STNX61MJYM");
  ReactGA.send("pageview");
  useEffect(() => {
    hotjar.initialize(3309419, 6);
  }, []);
  return (
    <div className="App">
      <UserContext.Provider>
        <AuthUser show={false} parentMethod={updateUser2}></AuthUser>

        <BrowserRouter>
          <Wrapper auth_user={auth_user}>
            <Route
              exact
              path="/"
              render={() => <Projects auth_user={auth_user} />}
            />
            <Route
              exact
              path="/project/:id"
              render={() => <Project auth_user={auth_user} />}
            />
          </Wrapper>
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
};

export default App;
