import React from "react";
import { Col, Button } from "antd";

const Submit = () => (
  <Col
    xs={{ span: 24 }}
    lg={{
      span: 24,
      offset: 0,
    }}
    style={{
      background: "white",
      padding: "24px",
    }}
  >
    <a
      href="https://shorted.in/submit/"
      target="_blank"
      rel="noreferrer"
      title="Submit your Short Film"
    >
      <Button type="primary">Submit New Project</Button>
    </a>
  </Col>
);
export default Submit;
