/* eslint-disable react/prop-types */
import React from "react";
import functions from "../../components/utils/functions";
import statusColor from "../../components/utils/satus";
const { Meta } = Card;
import { Card, Tag, Col } from "antd";
import { Link } from "react-router-dom";

const FilmCard = ({ item, deactive }) => (
  <Col
    key={item.uid}
    xs={{ span: 24 }}
    lg={{ span: 6 }}
    style={{ padding: "8px" }}
  >
    {deactive ? (
      <Card
        key={item.uid}
        cover={
          <img
            alt={item.title}
            src={
              item.photo_url
                ? item.photo_url
                : `https://i.ytimg.com/vi/${functions.get_videoID(
                    item.video_url
                  )}/maxresdefault.jpg`
            }
          />
        }
        style={{ width: "100%", marginBottom: 24 }}
      >
        <Meta
          title={item.title}
          description={<Tag color={"default"}>Not Eligble</Tag>}
        />
      </Card>
    ) : (
      <Link to={`/project/${item.uid}`}>
        <Card
          hoverable
          key={item.uid}
          cover={
            <img
              alt={item.title}
              src={
                item.photo_url
                  ? item.photo_url
                  : `https://i.ytimg.com/vi/${functions.get_videoID(
                      item.video_url
                    )}/maxresdefault.jpg`
              }
            />
          }
          style={{ width: "100%", marginBottom: 24 }}
        >
          <Meta
            title={item.title}
            description={
              <Tag
                color={
                  (statusColor[item.distributionStatus] &&
                    statusColor[item.distributionStatus].color) ||
                  "warning"
                }
                icon={
                  statusColor[item.distributionStatus] &&
                  statusColor[item.distributionStatus].icon
                }
              >
                {item.distributionStatus || "Activate"}
              </Tag>
            }
          />
        </Card>
      </Link>
    )}
  </Col>
);
export default FilmCard;
