/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { Divider, Layout, Row, Spin } from "antd";
import { db_user } from "../../components/firestore_user";

const { Content } = Layout;

import Submit from "../../components/submit";
import Projects from "./projects";
import Sales from "./sales";
import Payout from "./payout";

const Common = ({ auth_user }) => {
  const [projects, updateLeads] = useState([]);
  const [payoutsProject, updatePayoutsProject] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const [revenue, updateRevenue] = useState(0);
  const movies_load = async () => {
    try {
      const query = db_user
        .collection("projects")
        .where("username", "==", auth_user.user.uid);
      const querySnapshot = await query.get();
      var arrOfObj = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        uid: doc.id,
      }));
      updateLeads(
        arrOfObj.filter(
          (item) => item.status == "published" && item.country == "in"
        )
      );
      toggleLoading(false);
    } catch (error) {
      console.log(error);
      toggleLoading(false);
    }
  };

  const track = async () => {
    db_user
      .collection("trackers")
      .doc("distribution")
      .update({
        users: firebase.firestore.FieldValue.arrayUnion(auth_user.user.uid),
      });
  };
  const getRevenue = async () => {
    const projects = await db_user
      .collection("distribution")
      .where("user", "==", auth_user.user.uid)
      .get();
    updatePayoutsProject(projects);
    let total = 0;
    projects.docs
      .map((doc) => doc.data())
      .forEach((data) => {
        const revArr = data.platform
          ? data.platform.filter((item) => item.revenue_v2)
          : [];
        // find revenue sum
        if (revArr.length > 0) {
          const sum =
            revArr
              .map((item) =>
                item.revenue_v2.reduce(
                  (n, { revenue }) => n + parseInt(revenue),
                  0
                )
              )
              .reduce((a, b) => a + b, 0)
              .toFixed(2) * 1;
          total += sum;
        }
      });
    updateRevenue(total * 0.9);
    // track reenue sum
    if (total > 0) {
      db_user
        .collection("trackers")
        .doc("distribution")
        .update({
          revenue: firebase.firestore.FieldValue.arrayUnion({
            user: auth_user.user.uid,
            revenue: total,
          }),
        });
    }
    // find payouts
  };
  useEffect(() => {
    if (auth_user.user && auth_user.user.uid) {
      movies_load();
      track();
      getRevenue();
    }
  }, [auth_user.user]);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            className="site-layout-background"
            style={{
              marginTop: 24,
              minHeight: 280,
            }}
          >
            {loading ? (
              <Spin />
            ) : (
              <Row>
                <Submit />
                <Divider />
                <Projects projects={projects} />
              </Row>
            )}
            {projects.filter(
              (item) => item.distributionStatus == "live" || item.rating >= 6.5
            ).length > 0 && (
              <>
                <Sales revenue={revenue} />
                <Divider />
                <Payout projects={payoutsProject && payoutsProject.docs} />
              </>
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default Common;
