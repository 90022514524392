/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { TextLoop } from "react-text-loop-next";

import {
  Alert,
  Button,
  Breadcrumb,
  Divider,
  Layout,
  Col,
  Row,
  List,
  Typography,
  Steps,
  Empty,
  Spin,
} from "antd";
import { db } from "../../components/firestore";
import { db_user } from "../../components/firestore_user";
import { Link, useParams } from "react-router-dom";
import firebase from "firebase";
import statusColor from "../../components/utils/satus";
const { Content } = Layout;
const { Step } = Steps;

import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
const { Title } = Typography;
import functions from "../../components/utils/functions";
import RegisterForm from "./form";
import Platform from "../../components/platforms";
import Contract from "./contract";
import Metric from "./metric";
import MetricV2 from "./metric/v2";
import Submit from "../../components/submit";

//workflow : inactive -> incomplete ->  review -> contract/blocked -> live

const checklistStatus = ["incomplete", "blocked", "review"];

const blocked_status = {
  "access-missing":
    "We do not have the Google Drive access. Please provide the editor access to support@shortedfilms.com.",
  "subtitle-missing":
    "The film is missing subtitles. Please refer the template folder and provide a subtitle file as they are mandatory.",
  "subtitle-burnt":
    "The subtiltes are burnt on the film. Please provide them as a separate file and not on the video.",
  "files-missing":
    "The files are missing. Please provide the files as per the template folder.",
};
const Common = ({ auth_user }) => {
  const [project, updateLeads] = useState({});
  const [loading, toggleLoading] = useState(true);
  const [checklist, updateChecklist] = useState([]);
  const [distribution, updateDistribution] = useState({});
  const [lastUpdated, updateLastUpdated] = useState(null);
  const id = useParams().id;
  const movies_load = async () => {
    try {
      const doc = await db_user.collection("projects").doc(id).get();
      updateLeads(doc.data());
      toggleLoading(false);
      db_user
        .collection("trackers")
        .doc("distribution")
        .update({
          users: firebase.firestore.FieldValue.arrayUnion(auth_user.user.uid),
          projects: firebase.firestore.FieldValue.arrayUnion(
            doc.data() ? doc.id : ""
          ),
        });
      const docChecklist = await db.collection("pages").doc("homepage").get();
      docChecklist.data() && updateChecklist(docChecklist.data());
      const docDis = await db_user.collection("distribution").doc(id).get();
      docDis.data() && updateDistribution(docDis.data());
    } catch (error) {
      console.log(error);
      toggleLoading(false);
    }
  };
  const handleLastUpdated = (val) => {
    updateLastUpdated(val);
  };
  useEffect(() => {
    if (auth_user.user && auth_user.user.uid) {
      movies_load();
    }
  }, [auth_user.user]);

  const updateStatus = async () => {
    const doc = await db_user.collection("projects").doc(id).get();
    updateLeads(doc.data());
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout style={{ padding: "0 0px 24px" }}>
        <Content
          className="site-layout-background"
          style={{
            margin: 0,
            minHeight: 280,
          }}
        >
          <Divider />
          {loading ? (
            <Spin />
          ) : (
            <Row>
              <Col
                xs={{ span: 24 }}
                lg={{
                  span: project.distributionStatus === "live" ? 16 : 12,
                  offset: project.distributionStatus === "live" ? 4 : 6,
                }}
                style={{
                  background: "white",
                  padding: "24px",
                  textAlign: "left",
                }}
              >
                {project?.pro === "success" ||
                (project &&
                  project.status == "published" &&
                  project.rating >= 6.5 &&
                  project.country == "in") ? (
                  <>
                    {checklist.notifications && (
                      <Alert
                        banner
                        message={
                          <TextLoop mask noWrap={false}>
                            {checklist.notifications.map((item, index) => (
                              <div key={index}>{item}</div>
                            ))}
                          </TextLoop>
                        }
                        type="info"
                        style={{
                          marginBottom: "24px",
                          height: window.innerWidth < 530 ? "76px" : "40px",
                        }}
                      />
                    )}
                    <Breadcrumb separator="/">
                      <Breadcrumb.Item>
                        <Link to={`/`}>My Projects</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>{project.title}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <Link to={`/`}>
                        {" "}
                        <ArrowLeftOutlined
                          style={{ fontSize: 20, marginRight: 16 }}
                        />
                      </Link>
                      <Title level={2}>{project.title}</Title>
                    </div>
                    {!["contract", "live"].includes(
                      project.distributionStatus
                    ) && (
                      <>
                        <img
                          alt={project.title}
                          src={
                            project.photo_url
                              ? project.photo_url
                              : `https://i.ytimg.com/vi/${functions.get_videoID(
                                  project.video_url
                                )}/maxresdefault.jpg`
                          }
                          width="100%"
                        />
                        <Divider />
                      </>
                    )}

                    {!project.distributionStatus === "live" && checklist.steps && (
                      <Steps
                        current={
                          project.distributionStatus === "live"
                            ? 2
                            : project.distributionStatus === "contract"
                            ? 1
                            : 0
                        }
                        direction={
                          window.innerWidth < 530 ? "vertical" : "horizontal"
                        }
                      >
                        {checklist.steps.map((item, index) => (
                          <Step
                            key={index}
                            title={item.title}
                            description={item.description}
                          />
                        ))}
                      </Steps>
                    )}
                    {project.distributionStatus === "live" &&
                      project.distributionLicense &&
                      project.distributionLicense.fireBaseUrl && (
                        <>
                          <Divider />
                          <a
                            href={project.distributionLicense.fireBaseUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button>View Contract</Button>
                          </a>
                        </>
                      )}
                    <Divider />
                    <Alert
                      message={
                        statusColor[project.distributionStatus || "inactive"]
                          .title
                      }
                      description={
                        <>
                          {project.distributionStatus == "blocked" &&
                          project.distributionBlockedReason
                            ? `${
                                blocked_status[
                                  project.distributionBlockedReason
                                ]
                              } Let us know at support@shortedfilms.com once you have made the changes.`
                            : statusColor[
                                project.distributionStatus || "inactive"
                              ].description}
                        </>
                      }
                      type={
                        statusColor[project.distributionStatus || "inactive"]
                          .type
                      }
                      showIcon
                      style={{
                        fontFamily: "Raleway",
                        marginTop: "24px",
                        marginBottom: "24px",
                      }}
                    />
                    {project.distributionStatus === "contract" && (
                      <Contract project={project} />
                    )}
                    {project.distributionStatus === "live" && (
                      <Platform
                        distribution={distribution}
                        handleLastUpdated={handleLastUpdated}
                      />
                    )}
                    {project.distributionStatus === "live" &&
                      distribution &&
                      distribution.platform &&
                      distribution.platform.filter((item) =>
                        ["live", "offline"].includes(item.status)
                      ).length > 0 && (
                        <>
                          {distribution.title === "Dhundasas" ? (
                            <Metric
                              distribution={distribution}
                              lastUpdated={lastUpdated}
                            />
                          ) : (
                            <MetricV2
                              distribution={distribution}
                              lastUpdated={lastUpdated}
                            />
                          )}
                        </>
                      )}
                    <Divider />
                    {checklistStatus.includes(project.distributionStatus) && (
                      <>
                        <b>[IMPORTANT] </b>The folder structure should be in the
                        following format -{" "}
                        <a
                          href={`${checklist.checklist_drive}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download Template
                        </a>
                        <Divider />
                      </>
                    )}
                    {(!project.distributionStatus ||
                      project.distributionStatus === "inactive" ||
                      project.distributionStatus === "incomplete") && (
                      <>
                        <RegisterForm
                          auth_user={auth_user}
                          status={project.distributionStatus}
                          uid={id}
                          updateStatus={updateStatus}
                          project={project}
                        />
                        <Divider />
                      </>
                    )}
                    {checklistStatus.includes(project.distributionStatus) && (
                      <>
                        <Title level={4}>Checklist</Title>
                        {checklist.checklist_drive && (
                          <List
                            itemLayout="horizontal"
                            dataSource={checklist.checklist}
                            renderItem={(item, index) => (
                              <List.Item>
                                <List.Item.Meta
                                  avatar={
                                    distribution.checklist &&
                                    distribution.checklist.split("")[index] ==
                                      1 ? (
                                      <CheckCircleFilled
                                        style={{ fontSize: 20, color: "green" }}
                                      />
                                    ) : (
                                      <CloseCircleFilled
                                        style={{ fontSize: 20, color: "grey" }}
                                      />
                                    )
                                  }
                                  title={item.title}
                                  description={item.description}
                                />
                              </List.Item>
                            )}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Empty
                    status="500"
                    title="You have no projects"
                    subTitle="Start submitting now!"
                    description={<span>Not a valid project</span>}
                  ></Empty>
                )}
              </Col>
            </Row>
          )}
        </Content>
      </Layout>
      {project && project.distributionStatus !== "contract" && (
        <Row>
          <Submit />
        </Row>
      )}
    </Layout>
  );
};
export default Common;
