/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Divider, Layout, Typography, Table, Tag, Space, Row } from "antd";

const { Content } = Layout;

const { Title } = Typography;

const platforms = {
  zEAGwI1OWReb9aPFQRsV: "MX Player",
  uxrk08UWP9tKE0ehFf67: "Disney+ Hotstar",
  WdkSRjlvGdM24eJpym8D: "Hungama",
  UQDYz1fccATzzM9LvgAW: "BookMyshow Stream",
  KcdjLvvTVE3k6vV3kXNq: "Shorted Stream",
};
const tags = {
  pending: "warning",
  success: "success",
  initiated: "processing",
  failed: "error",
};

const columns = [
  {
    title: "Project",
    dataIndex: "title",
    key: "title",
    fixed: "left",
  },
  {
    title: "Month",
    dataIndex: "month",
    key: "month",
  },
  {
    title: "Platform",
    dataIndex: "platform",
    key: "platform",
    render: (text) => platforms[text],
  },
  {
    title: "Payout",
    dataIndex: "revenue",
    key: "payout",
    render: (text) =>
      `₹${(0.65 * 0.9 * text).toFixed(2).toLocaleString("en-IN")}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <Tag color={tags[text] || "warning"}>{text || "Pending"} </Tag>
    ),
  },
];

const Payout = ({ projects }) => {
  const [payouts, updatePayouts] = useState([]);
  const getPayout = () => {
    const payoutArr = [];
    projects
      .map((doc) => ({ uid: doc.id, ...doc.data() }))
      .forEach((data) => {
        data.platform &&
          data.platform.forEach((platform) => {
            if (platform.revenue_v2) {
              platform.revenue_v2.forEach((revenue) => {
                if (revenue.revenue > 0) {
                  payoutArr.push({
                    revenue: revenue.revenue,
                    month: revenue.month,
                    title: data.title,
                    project: data.uid,
                    platform: platform.id,
                    timestamp: revenue.timestamp,
                    status: revenue.status || "pending",
                  });
                }
              });
            }
          });
      });
    updatePayouts(payoutArr.sort((a, b) => b.timestamp - a.timestamp));
  };
  useEffect(() => {
    if (projects && projects.length > 0) {
      getPayout();
    }
  }, [projects]);
  return (
    <Content
      style={{
        padding: 24,
        margin: 0,
        background: "white",
      }}
    >
      <Title level={4}>Payouts</Title>
      <Divider />
      {payouts.length > 0 ? (
        <>
          <Table
            dataSource={payouts}
            columns={columns}
            pagination={{
              position: ["bottomRight"],
            }}
            scroll={{
              x: 600,
            }}
          />
          <Space direction="vertical" style={{ textAlign: "left" }}>
            <Row>
              <Tag color="warning">pending</Tag> : Payment not received from the
              platform.
            </Row>
            <Row>
              <Tag color="processing">initiated</Tag> : Payment received from
              the platform and is initiated to be transferred.
            </Row>
            <Row>
              <Tag color="success">success</Tag> : Payment is successfully
              transferred to your account
            </Row>
            <Row>
              <Tag color="error">failed</Tag> : Payment has failed. Please
              contact support.
            </Row>
          </Space>
        </>
      ) : (
        <>You do not have any pending payouts.</>
      )}
    </Content>
  );
};
export default Payout;
