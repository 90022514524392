import firebase from "firebase";
var config = {
  apiKey: "AIzaSyBwgeYI-8jPXCmlvFbWcBqeE5PKETKdEUQ",
  authDomain: "shorted-distribution.firebaseapp.com",
  projectId: "shorted-distribution",
  storageBucket: "shorted-distribution.appspot.com",
  messagingSenderId: "696916835338",
  appId: "1:696916835338:web:4bee5095b707e7659e73f3",
  measurementId: "G-NX9Q0HJJK5",
};
try {
  firebase.initializeApp(config);
} catch (err) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack);
  }
}
// Initialize Cloud Firestore through Firebase
var db = firebase.firestore();
var auth = firebase.auth();
// Disable deprecated features

export { db, auth };
