/* eslint-disable react/prop-types */
import React from "react";
import { Menu, Layout } from "antd";
import { object, string } from "prop-types";
import { Link } from "react-router-dom";
import { UserOutlined, PlusOutlined } from "@ant-design/icons";

const { Header } = Layout;

const propTypes = {
  auth: object,
  selected: string,
};

const TopNav = ({ auth_user, selected }) => {
  return (
    <Header
      className="header"
      style={{
        display: "flex",
        height: "auto",
        padding: "10px 20px 10px 10px",
        background: "transparent",
        position: "relative",
      }}
    >
      {(!auth_user.user || !auth_user.user.uid) && (
        <Link to={`/`}>
          <div className="logo">
            {
              <img
                src="/logo.webp"
                width="150px"
                alt="Shorted distribution programme"
              />
            }
          </div>
        </Link>
      )}

      <Menu
        mode="horizontal"
        defaultSelectedKeys={[selected]}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          background: "transparent",
          border: "transparent",
        }}
      >
        <Menu.Item
          className="nav-tab"
          key="link"
          style={{
            lineHeight: auth_user.user && auth_user.user.uid ? 2 : 4,
          }}
        >
          <a
            href={`https://shorted.in/submit/`}
            target="_blank"
            rel="noreferrer"
            title="Submit your Short Film"
            style={{
              color: auth_user.user && auth_user.user.uid ? "initial" : "white",
            }}
          >
            <PlusOutlined /> Submit
          </a>
        </Menu.Item>
        {auth_user.user && auth_user.user.uid ? (
          <Menu.Item
            className="nav-tab"
            key="logout"
            onClick={auth_user.signOut}
            style={{ lineHeight: 2 }}
          >
            <UserOutlined /> Logout
          </Menu.Item>
        ) : (
          <>
            <Menu.Item
              className="nav-tab"
              key="logout"
              onClick={() => {
                const element = document.getElementById("login");
                console.log(element);
                element.scrollIntoView({ behavior: "smooth" });
              }}
              style={{ lineHeight: 4, color: "white" }}
            >
              <UserOutlined /> Login
            </Menu.Item>
          </>
        )}
      </Menu>
    </Header>
  );
};

TopNav.propTypes = propTypes;
export default TopNav;
