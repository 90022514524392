/* eslint-disable react/prop-types */
import React from "react";
import { Divider, Layout, Typography, Row, Col, Statistic } from "antd";

const { Content } = Layout;

const { Title } = Typography;
const Sales = ({ revenue }) => (
  <Content
    style={{
      padding: 24,
      marginTop: 20,
      background: "white",
    }}
  >
    <Title level={4}>Sales</Title>
    <Divider />
    <Row gutter={16}>
      <Col
        className="gutter-row"
        xs={{ span: 12 }}
        lg={{ span: 8 }}
        style={{ padding: "16px" }}
      >
        <Statistic
          title="Net Revenue"
          value={`₹${revenue.toFixed(2).toLocaleString("en-IN")}`}
        />
      </Col>
      <Col
        className="gutter-row"
        xs={{ span: 12 }}
        lg={{ span: 8 }}
        style={{ padding: "16px" }}
      >
        <Statistic
          title="SHORTED(35%)"
          value={`₹${(0.35 * revenue).toFixed(2).toLocaleString("en-IN")}`}
        />
      </Col>
      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        lg={{ span: 8 }}
        style={{
          backgroundColor: "rgba(25,144,255,0.3)",
          padding: "16px",
        }}
      >
        <Statistic
          title="Net Payout"
          value={`₹${(0.65 * revenue).toFixed(2).toLocaleString("en-IN")}`}
        />
      </Col>
    </Row>
  </Content>
);
export default Sales;
