const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

var functions = {
  value: 2,
  get_videoID: function (arg) {
    if (arg != undefined) return arg.split("v=")[1];
    else return 0;
  },

  get_date: function (timestamp) {
    var curdate = new Date(null);
    curdate.setTime(timestamp.seconds * 1000);
    return (
      monthNames[curdate.getMonth()] +
      " " +
      curdate.getDate() +
      ", " +
      curdate.getFullYear()
    );
  },
  get_utc_date(timestamp) {
    var curdate = new Date(null);
    curdate.setTime(timestamp.seconds * 1000);
    /*var date = curdate.getDate();
    var month = curdate.getMonth();
    var hours = curdate.getHours();
    var minutes = curdate.getMinutes();
    var seconds = curdate.getSeconds();
    
    
    if (date < 10) {
      date = "0" + date;
    }
    month = month + 1
    if (month < 10) {
      month = "0" + month;
    }
    return curdate.getFullYear() + "-" + month + "-" + date + "T"+hours+":"+minutes+":"+seconds+"+00:00"*/
    return curdate.toISOString();
  },

  get_url: function (string) {
    return string
      .toLowerCase()
      .trim()
      .replace(/[.,'\s]+/g, "-");
  },
  get_title: function (string) {
    return string.trim().split("|")[0];
  },
  //this is the function responsible to update the state in short films
  updateState(text) {
    this.setState({ text });
  },
};

export default functions;

/*
export function currYear() {
    var year = new Date().getFullYear();
     return year;
  }
*/
