/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import TopNav from "../../components/top-navigation";
import SiderBar from "../../components/sider";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import { auth as firebaseAppAuthUser } from "../../components/firestore_user";
import { db } from "../../components/firestore";
import Buttons from "./buttons";
import { Alert, Row, Col, Divider, Layout, Typography } from "antd";
import { TextLoop } from "react-text-loop-next";
import Common from "./common";
import IsMobileView from "../../components/utils/mobileView";
const isFacebookApp = () => {
  try {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  } catch {
    return false;
  }
};

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: !isFacebookApp() ? "popup" : "redirect",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  signInSuccessUrl: false,
};

const { Content } = Layout;
const { Title } = Typography;

const Wrapper = ({ auth_user, children }) => {
  const [checklist, updateChecklist] = useState([]);
  const movies_load = async () => {
    try {
      const docChecklist = await db.collection("pages").doc("homepage").get();
      docChecklist.data() && updateChecklist(docChecklist.data());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    movies_load();
  }, []);
  const isMobileView = IsMobileView();
  return (
    <React.Fragment>
      <>
        {auth_user && auth_user.user ? (
          <Layout style={{ minHeight: "100vh" }}>
            <SiderBar selectedKey={["1"]} openKey={["sub1", "sub2", "sub3"]} />
            <Layout>
              <TopNav auth_user={auth_user}></TopNav>
              {children}
              <Row style={{ background: "white", margin: "24px 0px" }}>
                <Common checklist={checklist} auth_user={auth_user} />
              </Row>
            </Layout>
          </Layout>
        ) : (
          <Layout style={{ minHeight: "100vh", background: "white" }}>
            <Content
              style={{
                position: "relative",
                width: "100%",
                height: "90vh",
                left: "0px",
                top: "0px",
                backgroundImage: isMobileView
                  ? `linear-gradient(to bottom, #000000 0%, rgba(0,0,0,0.2) 70%, #000000 100%), url('/banner_mob.jpg')`
                  : `linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), rgba(0,0,0,0), rgba(0,0,0,0)), url('/banner.jpg')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: isMobileView ? "center bottom" : "center",
                backgroundColor: "black",
              }}
            >
              {/*<img
                src="/bg.webp"
                width="100%"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "auto",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 0,
                }}
                alt="Shorted distribution programme"
              />*/}
              <Row
                style={{
                  background:
                    "linear-gradient(to bottom, #000000, rgba(0,0,0,0))",
                }}
              >
                <Col md={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
                  <TopNav auth_user={auth_user}></TopNav>
                </Col>
              </Row>
              <Row
                style={{
                  height: "calc(100% - 84px)",
                  textAlign: isMobileView ? "center" : "left",
                  flexDirection: isMobileView ? "row" : "column",
                  justifyContent: "space-between",
                  padding: "10px",
                  paddingBottom: isMobileView ? 8 : 32,
                }}
              >
                <Col md={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                  <Title
                    style={{
                      color: "white",
                      fontWeight: "900",
                      marginTop: 32,
                      fontSize: isMobileView ? 40 : 56,
                    }}
                  >
                    Distribute Your
                    <br /> Short Films <br /> Like a Pro
                  </Title>
                  <Title
                    level={2}
                    style={{
                      color: "hsla(213, 84%, 94%, 1)",
                      marginBottom: 32,
                      fontSize: isMobileView ? 16 : 18,
                      fontWeight: 100,
                    }}
                  >
                    {" "}
                    Short Films featured on SHORTED are eligible for
                    distribution and monetisation through our TV & OTT partners.
                  </Title>
                </Col>
                <Col
                  md={{ span: 22, offset: 1 }}
                  lg={{ span: 8, offset: 2 }}
                  style={
                    isMobileView && {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: isMobileView ? "center" : "flex-start",
                    }
                  }
                >
                  <Buttons />
                </Col>
                {/*<Col xs={{ span: 24 }} lg={{ span: 12, offset: 6 }}>
                  <img
                    src="/banner-2.webp"
                    width="75%"
                    alt="Shorted distribution programme"
                  />
                </Col> */}
              </Row>
            </Content>
            {/*<svg
              viewBox="0 0 1446 199"
              focusable="false"
              className="chakra-icon css-1w3t3sf"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M -6 68.5 V 0 H 1445 V 91 L 1289 170 L -6 68.5 Z"
                fill="#000000"
              ></path>
              </svg>*/}
            <Common checklist={checklist} auth_user={auth_user} />
            <Col
              className="gutter-row"
              md={{ span: 24 }}
              lg={{ span: 16, offset: 4 }}
              style={{
                display: "flex",
                marginTop: 0,
                flexDirection: "column",
                background: "white",
                padding: "24px",
              }}
            >
              {checklist.notifications && (
                <Alert
                  banner
                  message={
                    <TextLoop mask noWrap={false}>
                      {checklist.notifications.map((item, index) => (
                        <div key={index}>{item}</div>
                      ))}
                    </TextLoop>
                  }
                  type="info"
                  style={{
                    marginBottom: "24px",
                    height: window.innerWidth < 530 ? "76px" : "40px",
                  }}
                />
              )}
              <div
                className="landing"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
                id="login"
              >
                <Content style={{ maxWidth: 600 }}>
                  <h3>Sign in to your SHORTED account</h3>
                  <p style={{ color: "grey", marginBottom: 32 }}>
                    Please use the same account you did while making your
                    submission on SHORTED. Distribution is only available for
                    films featured on SHORTED. To submit new projects please
                    click{" "}
                    <a
                      href="https://shorted.in/submit/"
                      target="_blank"
                      rel="noreferrer"
                      title="Submit your Short Film"
                    >
                      <u>here</u>
                    </a>
                    .
                  </p>
                  <StyledFirebaseAuth
                    uiConfig={uiConfig}
                    firebaseAuth={firebaseAppAuthUser}
                  />
                </Content>
              </div>
              <Divider />
              <br />
            </Col>
            {auth_user && !auth_user.user && <Buttons single={true} />}
          </Layout>
        )}
        <Layout>
          <Col
            className="gutter-row"
            md={{ span: 24 }}
            style={{
              display: "flex",
              flexDirection: "column",
              background: "white",
              padding: "24px",
            }}
          >
            <p>
              {auth_user && auth_user.user && (
                <>
                  {" "}
                  For support email us at <b>support@shortedfilms.com </b>{" "}
                  <br />
                  <br />
                </>
              )}
              © SHORTED 2023. All Rights Reserved.
              <br />
              <a href="https://shorted.in/terms-conditions/">
                Terms and Conditions
              </a>{" "}
              • <a href="https://shorted.in/privacy-policy/">Privacy Policy</a>
            </p>
          </Col>
        </Layout>
      </>
    </React.Fragment>
  );
};

export default Wrapper;
