/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Menu, Layout } from "antd";
import { Link } from "react-router-dom";
import {
  HomeFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
const { Content, Sider } = Layout;

const SiderBar = ({ openKey, selectedKey }) => {
  const isMobile = window.innerWidth < 530;
  const [collapsed, setCollapsed] = useState(isMobile);
  const menuProps = {
    className: "trigger",
    onClick: () => setCollapsed(!collapsed),
    style: {
      padding: "8px",
      background: "#001628",
      color: "white",
      fontSize: "16px",
    },
  };
  return (
    <Sider
      width={200}
      className="site-layout-background"
      trigger={null}
      collapsed={collapsed}
      collapsedWidth={50}
      style={{ position: "relative", zIndex: 2 }}
    >
      {!isMobile && (
        <Content
          style={{
            position: "fixed",
            left: !collapsed ? "200px" : "50px",
            transition: "all 0.2s",
          }}
        >
          {collapsed ? (
            <MenuUnfoldOutlined {...menuProps} />
          ) : (
            <MenuFoldOutlined {...menuProps} />
          )}
        </Content>
      )}
      <Link to={`/`}>
        <div className="logo" style={{ padding: "16px" }}>
          {!collapsed ? (
            <img
              src="/logo.png"
              width="150px"
              height="58.74px"
              alt="Shorted distribution programme"
            />
          ) : (
            <img
              src="/logo_mob.png"
              width="58.74px"
              height="58.74px"
              alt="Shorted distribution programme"
            />
          )}
        </div>
      </Link>
      <Menu
        mode="inline"
        defaultSelectedKeys={selectedKey}
        defaultOpenKeys={openKey}
        style={{ height: "100%", borderRight: 0, background: "transparent" }}
        theme="dark"
      >
        <Menu.Item key="1" icon={<HomeFilled />}>
          <Link to="/">Dashboard</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
export default SiderBar;
