import firebase from "firebase";
var secondary;
var db_user;
var auth;
var storage;
var config_user =
  process.env.NODE_ENV == "development" // test user account
    ? {
        apiKey: "AIzaSyAvdRV4NPmcr4-gPc7taCPCCmbD-BA9dec",
        authDomain: "shorted-staging.firebaseapp.com",
        databaseURL: "https://shorted-staging.firebaseio.com",
        projectId: "shorted-staging",
        storageBucket: "shorted-staging.appspot.com",
        messagingSenderId: "6477399570",
      }
    : {
        apiKey: "AIzaSyD2wuzHN8pSQaqE5RHhvemqrbCy9FZn7eE",
        authDomain: "shortedfilms.com",
        databaseURL: "https://shorted-user-4b544.firebaseio.com",
        projectId: "shorted-user-4b544",
        storageBucket: "shorted-user-4b544.appspot.com",
        messagingSenderId: "1052898354555",
      };
try {
  secondary = firebase.initializeApp(config_user, "secondary");
  db_user = secondary.firestore();
  auth = secondary.auth();
  storage = secondary.storage();
} catch (err) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack);
  }
}
// Initialize Cloud Firestore through Firebase

// Disable deprecated features

export { db_user, auth, storage };
