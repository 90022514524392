/* eslint-disable react/prop-types */
import React from "react";
import { Button, Empty, Divider, Col, Layout, Row, Typography } from "antd";
import FilmCard from "../../../components/card";

const { Title } = Typography;

const Projects = ({ projects }) => (
  <>
    {projects &&
      projects.filter((item) => item.distributionStatus == "live").length >
        0 && (
        <Layout>
          <Row>
            <Title level={4}>Live Projects</Title>
          </Row>
          <Row>
            {projects
              .filter((item) => item.distributionStatus == "live")
              .map((item) => (
                <FilmCard item={item} key={item.uid} />
              ))}
          </Row>
        </Layout>
      )}

    {projects.filter(
      (item) => item.distributionStatus != "live" && item.rating >= 6.5
    ).length > 0 ? (
      <Layout>
        <Row>
          <Title level={4}>Eligible Projects</Title>
        </Row>
        <Row>
          {projects
            .filter(
              (item) => item.distributionStatus != "live" && item.rating >= 6.5
            )
            .map((item) => (
              <FilmCard item={item} key={item.uid} />
            ))}
        </Row>
      </Layout>
    ) : (
      <>
        {projects &&
          projects.filter((item) => item.distributionStatus == "live").length <=
            0 && (
            <>
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                style={{
                  background: "white",
                  padding: "24px",
                  textAlign: "left",
                }}
              >
                <Empty
                  status="500"
                  title="You have no projects"
                  subTitle="Start submitting now!"
                  description={
                    <span>
                      You do not have {projects.length > 0 ? "other" : "any"}{" "}
                      eligible projects. Start submitting now!
                    </span>
                  }
                >
                  <a
                    href="https://shorted.in/submit/"
                    target="_blank"
                    rel="noreferrer"
                    title="Submit your Short Film"
                  >
                    <Button type="primary">Get Started</Button>
                  </a>
                </Empty>
              </Col>
              <Row>
                <Divider />
                <Title level={4}>Non Eligible Projects</Title>
                <Divider />
                {projects
                  .filter(
                    (item) =>
                      item.distributionStatus != "live" && item.rating < 6.5
                  )
                  .map((item) => (
                    <FilmCard item={item} key={item.uid} deactive />
                  ))}
                <Col xs={{ span: 24 }}>
                  <a
                    href="https://shorted.in/submit/"
                    target="_blank"
                    rel="noreferrer"
                    title="Submit your Short Film"
                  >
                    <Button type="primary">Add New Projects</Button>
                  </a>
                </Col>
                <Divider />
              </Row>
            </>
          )}
      </>
    )}
  </>
);
export default Projects;
