import React from "react";
import PropTypes from "prop-types";
import IsMobileView from "../utils/mobileView";

const H2 = (props) => {
  const { color, children } = props;
  const style = {
    color,
    fontWeight: "900",
    fontSize: IsMobileView() ? "28px" : "40px",
    maxWidth: "640px",
    margin: IsMobileView() ? "0px 24px" : "24px auto",
  };

  return <h2 style={style}>{children}</h2>;
};

H2.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default H2;
