import React from "react";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
const statusColor = {
  inactive: {
    color: "warning",
    type: "warning",
    icon: <ExclamationCircleOutlined />,
    title: "Your submission is inactive.",
    description: "Get started by filling the form below.",
  },
  incomplete: {
    color: "warning",
    type: "warning",
    icon: <ExclamationCircleOutlined />,
    title: "Share the files.",
    description:
      "Thank you for providing the details. Please provide the files in a SINGLE Google Drive folder (checklist and template shared below).",
  },
  review: {
    color: "processing",
    type: "info",
    icon: <SyncOutlined />,
    title: "Your submission is in review.",
    description:
      "Thank you for providing the files. Our team is reviewing the files and will notify you if anything is required at your end.",
  },
  contract: {
    color: "warning",
    type: "warning",
    icon: <ClockCircleOutlined />,
    title: "Please fill and sign the contract.",
    description: "Please review the contract below and fill the details.",
  },
  "contract-filled": {
    color: "warning",
    type: "warning",
    icon: <ClockCircleOutlined />,
    title: "We have received the contract.",
    description:
      "You will be notified with the signed contract from our end. We will also update you when the film is live on our distribution network.",
  },
  blocked: {
    color: "error",
    type: "error",
    icon: <CloseCircleOutlined />,
    title: "Your submission is blocked.",
    description:
      "We have received your files, however, they are incomplete. Please refer to the checklist and update the missing files one the same Google Drive folder and notify us or reach our for clarifications at support@shortedfilms.com.",
  },
  rejected: {
    color: "error",
    type: "error",
    icon: <CloseCircleOutlined />,
    title: "Your submission is rejected.",
    description:
      "We have received your files, however, we cannot take it ahead at this point.",
  },
  live: {
    color: "success",
    type: "success",
    icon: <CheckCircleOutlined />,
    title: "Your submission is live.",
    description:
      "Your submission is live and being sent to platforms. Track the revenue streams and sales on the dashboard.",
  },
};

export default statusColor;
