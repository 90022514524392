/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import withFirebaseAuth from "react-with-firebase-auth";
import firebase from "firebase";
import "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { auth } from "../firestore_user";

const uiConfig = {
  // Popup signin flow rather than redirect flow.

  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  signInSuccessUrl: "/",
};

const firebaseAppAuth = auth;

const providers = {};

const Auth = ({ show, parentMethod, user, signOut }) => {
  const auth = {
    user: user,
    signOut: signOut,
  };

  const findUser = () => {
    //console.log(auth);
    parentMethod(auth ? auth : "null");
  };
  useEffect(() => {
    findUser();
  }, [auth && auth.user && auth.user.uid]);
  return show && <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(Auth);
